header {
    z-index: 100;
    position: relative;
    .header-logo {
        max-height: 50px;
        margin-top: -30px;
    }
    .header-logo-title {
        color: white;
        font-size: 20px;
        line-height: 1.15;
        letter-spacing: 2.07px;
        text-transform: uppercase;
        white-space: pre-line;
        display: inline-block;
        margin-bottom: 0;
        margin-left: 10px;
        margin-top: 10px;
    }
    .header-logo-text{
        color: white;
        font-size: 12px;
        line-height: 1.92;
        letter-spacing: 1.24px;
        margin: 0;
    }
    .header-company-link-name{
        margin: 0;
        font-size: 16px;
        line-height: 1.44;
        letter-spacing: 1.65px;
        color: white;
        &.active{
            font-weight: bold;
            position: relative;
            z-index: 10;
            &:after{
                content:"";
                background-color: rgba(100,20,200, 0.5);
                width: 50px;
                height: 20px;
                box-shadow: 0 0 10px 10px rgba(100,20,200, 0.5);
                position: absolute;
                top: 0;
                left: -20px;
                border-radius: 30px;
                z-index: -1;
            }
             @include media-breakpoint-down(md) {
                &:after{
                    background: none;
                    border: none;
                    box-shadow: none;
                }
            }
        }
    }
    .header-company-link-text{
        margin: 0;
        font-size: 12px;
        line-height: 1.92;
        letter-spacing: 1.24px;
        color: white;
    }
    .header-contact-button{
        width: 200px;
        height: 50px;
        border-radius: 28px;
        background-color: $blue;
        border: 2px solid $blue;
        color: white;
        font-size: 18px;
        font-weight: 600;
        line-height: 1.15;
        letter-spacing: 2.07px;
        outline:none;
    }
    .header-lang{
        font-size: 12px;
        margin-right: 20px;
        padding-top: 10px;
        svg{
            path{
                fill: white;
            }
        }
    }
    .navbar-nav{
        @include media-breakpoint-down(md) {
            text-align: center;
        }
    }
}