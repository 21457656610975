@import "../../node_modules/bootstrap/scss/bootstrap.scss";

$blue: #27aae1;
$dark-blue: #04093e;

html{
   height: 100%; 
}
body {
    font-family: 'Montserrat', sans-serif;
    color: white;
    min-height: 100%;
    @include media-breakpoint-down(md) {
        height: auto;
        min-height: 100%;
    }
    #root{
        height: 100%;
        position: relative;
        min-height: 100vh;
    }
    .background-container{
        background-size: cover;
        background-position: center;
        height: 100vh;
        width: 100vw;
        position: relative;
        @include media-breakpoint-down(md) {
            height: 100%;
            min-height: 100vh;
            padding-bottom: 65px;
            position: static;
        }
    }
}

@import "header";
@import "footer";
@import "contact";
@import "element";
