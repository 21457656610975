.footer{
    position: absolute;
    bottom: 10px;
    width: 100%;
    text-align: center;
    border-top: solid 2px rgba(255,255,255,0.3);
    padding-top: 5px;
    .footer-container{
        display: inline-block;
        .footer-link{
            display: inline-block;
            a{
                color: white;
                display: inline-block;
                &:hover{
                    text-decoration: none;
                    cursor: pointer;
                }
            }
        }
        .footer-separator{
            color: white;
            display: inline-block;
            padding: 0 20px;
        }
        .footer-text{
            color: white;
            display: inline-block;
        }
    }
}