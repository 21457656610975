.contact-page{
    h1{
        font-size: 30px;
        line-height: 0.77;
        letter-spacing: 3px;
        color: #35a0da;
        margin: 12vh 0 20px;
    }
    .contact-form{
        .contact-form-input{
            display: block;
            width: 100%;
            border: none;
            background: none;
            margin-bottom: 25px;
            height: 50px;
            border-bottom: 2px solid #35a0da;
            color: white;
            &.contact-form-input-error{
                border-bottom: 2px solid red;
            }
        }
        .contact-form-textarea{
            display: block;
            width: 100%;
            height: 200px;
            resize: none;
            margin-bottom: 25px;
            border: none;
            background: none;
            border-bottom: 2px solid #35a0da;
            color: white;
            &.contact-form-textarea-error{
                border-bottom: 2px solid red;
            }
        }
        .contact-form-button{
            display: block;
            margin: 30px auto 0;
            text-align: center;
            font-size: 18px;
            line-height: 1.15;
            letter-spacing: 2.07px;
            color: #ffffff;
            width: 180px;
            height: 50px;
            border-radius: 28px;
            border: 1px solid #27aae1;
            background-color: #27aae1;
            outline:none;
            &:hover{
                text-decoration: none;
                cursor: pointer;
            }
        }
    }
}