.company-element{
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translateY(-50%) translateX(-50%);
    white-space: pre-wrap;
    @include media-breakpoint-down(md) {
        position: static;
        transform: none;
    }
    @include media-breakpoint-down(sm) {
        margin-bottom: 50px;
    }
    .company-element-circle-container{
        height: 850px;
        width: 850px;
        max-width: 100vh;
        max-height: 100vh;
        border-radius: 850px;
        border: 1px solid rgba(255, 255, 255, .2);
        margin: 0 auto;
        @include media-breakpoint-down(md) {
            max-width: 850px;
            width: 95%;
            border-radius: 30px;
            height: auto;
            border: none;
            max-height: none;
        }

        .company-element-circle{
            height: 620px;
            width: 620px;
            max-width: 80vh;
            max-height: 80vh;
            border-radius: 700px;
            box-shadow: 0 0 1px 10px rgba(255, 255, 255, .1);
            background-color: white;
            position: absolute;
            top: 50%;
            left: 50%;
            transform: translateY(-50%) translateX(-50%);
            @include media-breakpoint-down(md) {
                position: static;
                max-width: none;
                max-height: none;
                transform: none;
                margin: 30px auto 0;
            }
            @include media-breakpoint-down(md) {
                max-width: 620px;
                height: auto;
                width: 90%;
                border-radius: 30px;
                border: none;
                padding: 20px;
            }
            .company-element-image{
                width: 49%;
                display:inline-block;
                position: relative;
                top: -200px;
                left: -90px;
                img{
                    max-width: 350px;
                    max-height: 60vh;
                }
                @include media-breakpoint-down(md) {
                    width: 85%;
                    height: auto;
                    position: static;
                    text-align: center;
                    img{
                        max-width: 50%;
                    }
                }
            }
            .company-element-description{
                width: 49%;
                display:inline-block;
                color: black;
                @include media-breakpoint-down(md) {
                    width: 85%;
                    position: relative;
                    left: 50%;
                    transform: translateX(-50%);
                }
                .company-element-logo{
                    max-width: 100px;
                    max-height: 40px;
                    margin-top: 50px;
                    margin-bottom: 10px;
                    @include media-breakpoint-down(md) {
                        margin-top: 30px;
                    }
                }
                .company-element-social{
                    .company-element-social-icon{
                        margin-right: 30px;
                        svg{
                            path{
                                fill: rgba(0,0,0,0.6);
                            }
                        }
                    }
                }
                .company-element-head-text{
                    font-size: 20px;
                    line-height: 1.15;
                    letter-spacing: 2.07px;
                    color: #3d3d3d;
                }    
                .company-element-text{
                    font-size: 16px;
                    line-height: 1.44;
                    letter-spacing: 1.65px;
                    color: #3d3d3d;
                }
            }
            .company-element-view{
                margin-top: 5px;
                height: 50px;
                width: 100%;
                @include media-breakpoint-down(md) {
                    text-align: center;
                    position: relative;
                }
                .company-element-button{
                    color: white;
                    border-radius: 50px;
                    background-color: $blue;
                    font-size: 18px;
                    line-height: 1;
                    letter-spacing: 2px;
                    border-radius: 50px;
                    padding: 15px 20px;
                    display: inline-block;
                    &:hover{
                        text-decoration: none;
                        cursor: pointer;
                    }
                    @include media-breakpoint-down(md) {
                        position: absolute;
                        left: 50%;
                        transform: translateX(-50%);
                        width: 160px;
                    }
                }
            }
        }
    }
    .company-element-title{
        h1{
            font-size: 26px;
            line-height: 1.38;
            letter-spacing: 2.6px;
            text-align: center;
            color: white;
            span{
                display: block;
            }
        }
    }
}

.deneuville-element{
    .company-element-image{
        margin-right: -50px;
        margin-left: 30px;
    }
}
.holoperf-element{
    .company-element-image{
        margin-right: -40px;
        margin-left: 25px;
    }
}

.subforce-element{
    .company-element-image{
        top: -200px !important;
        margin-right: -30px;
        margin-left: 10px;
    }
}

.rectangle-container{
    width: 100%;
    text-align: center;
    margin: 20px 0 40px;
    .rectangle{
        width: 80%;
        border-radius: 30px;
        background-color: white;
        text-align: left;
        color: black;
        padding: 20px;
        margin: 0 auto;
        white-space: pre-line;
    }
}
.rectangle-title{
    text-align: center;
}

.ajouve-element{
    .company-element-circle-container{
        height: 700px;
        width: 700px;
        margin-top: -50px;
        @include media-breakpoint-down(md) {
            width: 100vw;
            height: auto;
            min-height: 70vh;
        }
    }
    .ajouve-text-container{
        width: 50%;
        margin-left: 50%;
        margin-top: 150px;
        @include media-breakpoint-down(md) {
            margin-left: 40%;
            margin-top: 100px;
        }
        .ajouve-text{
            color: white;
            width: 75%;
            @include media-breakpoint-down(md) {
                width: 100%;
            }
            .ajouve-logo-title {
                font-size: 20px;
                line-height: 1.15;
                letter-spacing: 2.07px;
                text-transform: uppercase;
                white-space: pre-line;
                display: inline-block;
                margin-bottom: 0;
                margin-left: 10px;
                margin-top: 10px;
            }
            .ajouve-logo-text{
                font-size: 12px;
                line-height: 1.92;
                letter-spacing: 1.24px;
                margin: 0;
                margin-bottom: 20px;
            }
            .ajouve-logo {
                max-height: 50px;
                margin-top: -30px;
            }
        }
    }
}